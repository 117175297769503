/* Sidebar.css */

/* General styling for sidebar */
.sidebar-container {
  padding: 16px;
  border-right: 1px solid var(----dark-gray, #57575B);
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.sidebar-toggle-buttons {
  display: flex;
  height: 35px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border: none;
  color: var(----dark-gray, #57575B);
  text-align: center;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}


.sidebar-toggle-buttons button {
  display: flex;
  height: 35px;
  padding: 4px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  border-radius: 5px;
  border: none;
  background-color: rgba(255, 255, 255, 0.10);
  color: rgba(217, 217, 217, 1);
}

.sidebar-toggle-buttons button.active {
  display: flex;
  height: 35px;
  padding: 4px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.50);


  color: var(----white, #FFF);
  text-align: center;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}


.sidebar-search-bar {
  display: flex;
  height: 30px;
  padding: 4px 9px;
  align-items: center;
  gap: 4px;
  align-self: stretch;
  color: var(----editor-text, #D9D9D9);

  border-radius: 3px;
  border: 0.7px solid var(----not-selected, #838389);
  background: #313031;
}

.sidebar-search-bar input {
  border: none;
  height: 100%;
  width: 100%;
  background-color: transparent;
}

/* Panel header styling */
.panel-header {
  font-size: 1.2em;
  margin-bottom: 12px;
  border-bottom: 1px solid #ccc;
  padding-bottom: 4px;
}

/* Draggable Element */
.draggable-element {
  padding: 8px;
  margin: 4px 0;
  color: #686868;
  border-radius: 5px;
  cursor: grab;
  transition: background-color 0.3s, opacity 0.3s;
  min-height: 50px;
  max-width: 100px;
}

.draggable-element:hover {
  background-color: rgba(80, 80, 80, 0.3);
}



.draggable-element.dragging {
  cursor: grab !important;
  /* Ensure grab cursor during dragging */
  opacity: 0.8;
}

.sidebar-title {
  margin-bottom: 10px;
}

/* Content section styling */
.content-section {
  margin-top: 16px;
  margin-bottom: 5vh;

}

.content-section h4 {
  color: var(----editor-text, #D9D9D9);
  text-align: left;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: flex;
  align-items: center;
  justify-content: space-between;

}


/* Default styling for bento-display-elements (elements panel) */
.bento-display-elements {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Default: 3 elements per row */
  grid-gap: 0.5rem;
  margin-bottom: 2rem;
}

.bento-display-elements img {
  width: 50px; /* Default smaller images */
  height: 50px;
}

/* Specific styling for layout displays */
.bento-display-layout .bento-display-elements {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* 2 elements per row for layout */
  grid-gap: 1rem; /* Adjust gap between items */
  margin-bottom: 2rem;
}
.bento-display-layout .bento-display-elements>*:nth-child(2n + 1):last-child {
  grid-column: 1 / -1;
}

.element-name {
  color: var(----editor-text, #D9D9D9);
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.element-category {
  cursor: pointer;
  user-select: none;
  margin-bottom: 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: rgba(217, 217, 217, 1);
}

.bento-extract-display {
  display: flex;
  flex-direction: column;
  align-items: center;
}

