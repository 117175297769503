/* Common panel styles */
.settings-panel {
  background-color: #313031;

  color: var(----editor-text, #D9D9D9);
  text-align: left;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.settings-header {
  margin: 15px 0 15px 0;
}

/* General settings group styling */
.settings-group {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: space-between;
}

.full-width {
  width: 100%;
}

.settings-panel input,
.settings-panel textarea,
.settings-panel select {
  width: 70%;
  border-radius: 7px;
  background: rgba(0, 0, 0, 0.45);
  color: var(----editor-text, #D9D9D9);
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 500;
  line-height: normal;
  padding: 8px;
  appearance: none;
  position: relative;
  box-sizing: border-box;
  border: none;
}

.settings-panel textarea {
  resize: vertical;
  min-height: 80px;
  border: none;
}

.settings-panel h3 {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  margin: 15px 0;
  display: flex;
  justify-content: space-between;
}

.settings-wrapper {
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: stretch;
  width: 100%;
  margin: 15px 0;
}

/* Code Editor Wrapper */


.expand-button {
  display: flex;
  padding: 2px 6px;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  background: var(----dark-gray, #57575B);
  border: none;
  color: var(----editor-text, #D9D9D9);
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
}

.expand-button:hover {
  background: #0056b3;
}

/* Expanded Mode */
.textual-settings-panel.expanded {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  z-index: 1000;
  padding: 20px;
  overflow: auto;
}

.code-editor-wrapper {
  display: flex;
  gap: 10px;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
}

.textual-settings-panel.expanded .code-editor-wrapper {
  height: 90%;
}

.textual-settings-panel.expanded textarea {
  height: 100%;
}

/* Code Editor */
.code-editor {
  display: flex;
  font-family: monospace;
  font-size: 14px;
  border-radius: 7px;
  background: rgba(0, 0, 0, 0.45);
  padding: 0;
  overflow: hidden;
  width: 100%;
}

/* Line Numbers */
.line-numbers {
  border-radius: 7px 0 0 7px;
  background: var(----dark-gray, #57575B);
  color: #888;
  padding-top: 10px;
  text-align: right;
  user-select: none;
}

.line-number {
  padding: 0 8px;
}

/* Scrollable and Custom Scrollbar for Textarea */
.code-input {
  flex: 1;
  font-family: monospace;
  font-size: 14px;
  padding: 8px;
  border: none;
  resize: none;
  outline: none;
  background: transparent;
  color: var(----editor-text, #D9D9D9);
  line-height: 1.4;
  overflow-y: auto;
  max-height: 300px;
}

/* Custom Scrollbar */
.code-input::-webkit-scrollbar {
  width: 8px;
}

.code-input::-webkit-scrollbar-thumb {
  background-color: rgba(33, 123, 244, 0.7);
  border-radius: 4px;
}

.code-input::-webkit-scrollbar-thumb:hover {
  background-color: rgba(33, 123, 244, 0.9);
}

.code-input::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}