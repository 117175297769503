.video-settings-panel {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-top: 15px;
    font-family: Montserrat, sans-serif;
    font-size: 14px;
    color: var(----editor-text, #D9D9D9);
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.video-settings-wrapper{
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.video-settings-panel input {
    border-radius: 7px;
    background: rgba(0, 0, 0, 0.45);
    color: var(----dark-gray, #57575B);
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.video-settings-panel hr {
    width: 100%;
}

.video-settings-panel input {
    width: 70%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
}


.video-preview-section {
    display: flex;
    padding: 5px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 5px;
    align-self: stretch;
    border: 1px solid var(----dark-gray, #57575B);
    color: var(----editor-text, #D9D9D9);
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.video-preview-section button {
    display: flex;
    padding: 3px 2px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-radius: 3px;
    background: var(----dark-gray, #57575B);
    width: 100%;
}

/* Placeholder Styling */
.video-settings-panel input::placeholder {
    color: rgba(217, 217, 217, 0.7);
    /* Placeholder color */
    font-style: italic;
}

/* Input filled and empty state */
.video-settings-panel input {
    border-radius: 7px;
    background: rgba(0, 0, 0, 0.45);
    font-family: Montserrat, sans-serif;
    font-size: 14px;
    padding: 8px;
    border: 1px solid #ccc;
    font-weight: 500;
}

.video-settings-panel input.empty {
    color: rgba(217, 217, 217, 0.7);
    /* Lighter color for empty inputs */
}

.video-settings-panel input.filled {
    color: var(----editor-text, #D9D9D9);
    /* Regular color for filled inputs */
}

.video-preview-wrapper {
    flex-shrink: 0;
}



.video-preview {
    width: 100%;
    height: auto;
    border-radius: 8px;
}

.video-info p {
    margin: 5px;
}

.replace-video-button {
    padding: 8px 12px;
    font-size: 14px;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.playback-settings {
    display: flex;
    gap: 10px;
}

.playback-settings-wrapper {
    gap: 10px;
    display: flex;
    flex-direction: column;
}

.playback-settings label {
    display: flex;
    gap: 15px;
    flex-direction: row;
}

.playback-settings label {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-family: Montserrat, sans-serif;
    font-size: 14px;
    color: var(----editor-text, #D9D9D9);
}

/* Hide the native checkbox */
.playback-settings input[type="checkbox"] {
    display: none;
}

/* Custom Checkbox */
.custom-checkbox {
    width: 12px;
    height: 12px;
    border: 2px solid var(----editor-text, #D9D9D9);
    border-radius: 4px;
    background: transparent;
    position: relative;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}

/* Checked state */
.custom-checkbox::after {
    content: "";
    width: 8px;
    height: 8px;
    background: var(----editor-text, #D9D9D9);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0);
    border-radius: 2px;
    transition: all 0.2s ease-in-out;
}

/* Show the check when checked */
.playback-settings input[type="checkbox"]:checked+.custom-checkbox::after {
    transform: translate(-50%, -50%) scale(1);
}

/* Hover Effect */
.custom-checkbox:hover {
    border-color: #007bff;
}