.wallet-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  border: 1px solid #ddd;
  padding: 20px;
  z-index: 1000;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  display: flex;
  flex-direction: column;

}

.popup-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.settings-popup {
  position: absolute;
}

.wallet-popup h3 {
  margin-top: 0;
  font-size: 18px;
}

.wallet-popup button {
  margin: 5px 0;
  padding: 10px 15px;
  border: none;
  background: grey;
  color: white;
  border-radius: 4px;
  cursor: pointer;
}

.wallet-popup button:hover {
  background: darkgrey;
}

.wallet-popup button:last-child {
  background: #dc3545;
}

.wallet-popup button:last-child:hover {
  background: #a71d2a;
}

.wallet-settings-panel hr {
  width: 100%;
}

.wallet-settings-panel {
  color: var(----editor-text, #D9D9D9);
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 10px;
}

.wallet-settings-panel .settings-group input {
  width: 70%;
  border-radius: 7px;
  background: rgba(0, 0, 0, 0.45);
  color: var(----editor-text, #D9D9D9);
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 500;
  line-height: normal;
  padding: 8px;
  appearance: none;
  position: relative;
  box-sizing: border-box;
  border: 0.5px solid var(----editor-text, #D9D9D9);
}

.wallet-setting {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 10px;
  gap: 15px;
}

.switch-label {
  display: flex;
  justify-content: space-between;
}

.switch {
  position: relative;
  display: inline-block;
  width: 34px;
  height: 20px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: '';
  height: 14px;
  width: 14px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked+.slider {
  background-color: #5C4EFA;
}

input:checked+.slider:before {
  transform: translateX(14px);
}

.upvote-message {
  margin-top: 20px;
  font-size: 10px;
  color: var(----dark-gray, #57575B);
  text-align: center;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.upvote-link {
  cursor: pointer;
  color: var(----dark-gray, #57575B);
  text-decoration: underline;
  font-weight: none ;
}

.upvote-link:hover {
  text-decoration: none;
}
