/* Global Material Symbols Styles */


.leftbar {
  height: 100vh;
  width: 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* Distribute the content evenly */
  align-items: center;
  background-color: #313031;
  border-right: 1px solid #57575B;
  padding: 10px 5px;
  /* Ensure some padding */
  box-sizing: border-box;
  z-index: 20;
  overflow: hidden;
}

.material-symbols-outlined {
  font-variation-settings: 'FILL' 0, 'wght' 400, 'GRAD' 0, 'opsz' 24;
  font-family: 'Material Symbols Outlined', sans-serif;
  font-size: 24px;
  /* Adjust size if needed */
}

/* Button Styling */
.icon-button {
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  color: rgba(131, 131, 137, 1);
}





.icon-button:hover {
  transform: scale(1.1);
}

.icon-button.active {
  color: rgba(255, 255, 255, 1);
  /* Active color */
  font-weight: bold;
  border-radius: 4px;
  background: rgba(255, 255, 255, 0.50);
}


.help-center-button:hover {
  color: red;
}



.buttons-group {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: auto;
  color: rgba(131, 131, 137, 1);
  /* Center the buttons-group */
  padding: none;
  
}

.help-center {
  margin-top: auto;
}

.help-center-button {
  color: rgba(131, 131, 137, 1);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  background-color: transparent;
}

.collapsible-panel {
  margin-bottom: 16px;
}

.panel-header h4 {
  margin: 0;
  font-size: 16px;
  font-weight: bold;
}

.panel-content {
  animation: fadeIn 0.3s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}


.draggable-navbar {
  padding: 8px;
  margin: 4px 0;
  background-color: #FBFBFB;
  border: 1px solid #ccc;
  color: #686868;
  border-radius: 5px;
  cursor: grab;
  transition: background-color 0.3s, opacity 0.3s;
}