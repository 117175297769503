/* Full-size dropdown for select inputs */
.full-size-input select {
    width: 100%;
  }
  
  /* Target input wrapper */
  .target-input {
    display: flex;
    gap: 15px;
    align-items: center;
  }
  
  /* Dropdown options styling */
  .link-settings-panel select option {
    background-color: rgba(0, 0, 0, 0.9);
    color: #838389;
    padding: 10px;
    font-size: 12px;
    font-weight: 500;
    font-family: Montserrat, sans-serif;
    border: none;
    box-sizing: border-box;
  }
  
  /* Dropdown hover and selected styles */
  .link-settings-panel select option:hover,
  .link-settings-panel select option:checked {
    background-color: rgba(0, 0, 0, 0.45);
    color: #fff;
    appearance: none;
  }
  
  /* Dropdown focus and hover states */
  .link-settings-panel select:hover,
  .link-settings-panel select:focus {
    background-color: rgba(0, 0, 0, 0.45);
    outline: none;
    color: #fff;
  }
  
  /* Dropdown header */
  .link-settings-panel h3 {
    font-family: Montserrat, sans-serif;
    font-size: 16px;
    font-weight: 600;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    user-select: none;
  }
  
  /* Wrapper for link settings */
  .link-settings-wrapper-target {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin: 15px 0;
  }
  
  /* Target input text */
  .link-settings-panel .target-input {
    color: var(----editor-text, #D9D9D9);
    font-family: Montserrat, sans-serif;
    font-size: 11px;
    font-weight: 400;
  }
  
  /* Custom checkbox styling */
  .custom-checkbox-input {
    display: none;
  }
  
  .custom-checkbox {
    width: 16px;
    height: 16px;
    border: 2px solid var(----editor-text, #D9D9D9);
    border-radius: 4px;
    background: transparent;
    position: relative;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
  }
  
  /* Checked state for the custom checkbox */
  .custom-checkbox::after {
    content: "";
    width: 10px;
    height: 10px;
    background: var(----editor-text, #D9D9D9);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0);
    border-radius: 2px;
    transition: all 0.2s ease-in-out;
  }
  
  .custom-checkbox-input:checked + .custom-checkbox::after {
    transform: translate(-50%, -50%) scale(1);
  }
  
  /* Checkbox hover effect */
  .custom-checkbox:hover {
    border-color: #007bff;
  }
  
  /* Checkbox label */
  .custom-checkbox-label {
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    font-family: Montserrat, sans-serif;
    font-size: 14px;
    color: var(----editor-text, #D9D9D9);
  }
  

  