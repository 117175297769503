.background-settings-panel .preview {
    margin-top: 10px;
    display: flex;
    flex-direction: column;

    
}

.background-settings-panel img,
.background-settings-panel video {
    max-width: 100%;
    border-radius: 8px;
    margin-top: 10px;
}

.clear-button {
    margin-top: 10px;
    padding: 8px 12px;
    background-color: #d9534f;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.clear-button:hover {
    background-color: #c9302c;
}

.background-preview-wrapper {
    display: flex;
    flex-direction: column !important;
    border: 1px solid var(----dark-gray, #57575B);
    padding: 5px;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
    color: var(----editor-text, #D9D9D9);
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.background-preview-wrapper button {
    display: flex;
    padding: 3px 2px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-radius: 3px;
    background: var(----dark-gray, #57575B);
    border: none;
    color: var(----editor-text, #D9D9D9);

    font-family: Montserrat;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    width: 100%;
}