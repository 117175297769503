.dropdown-settings {
    display: flex;
    flex-direction: column;
    gap: 15px;
}


/* Switch Wrapper */
.switch-label {
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    font-family: Montserrat, sans-serif;
    font-size: 14px;
    color: var(--editor-text, #d9d9d9);
    margin-bottom: 16px;
}

/* Hide default checkbox */
.switch-input {
    display: none;
}

/* Switch slider */
.switch-slider {
    width: 40px;
    height: 20px;
    border-radius: 20px;
    background-color: #d9d9d9;
    position: relative;
    transition: background-color 0.2s ease;
}

.switch-slider::before {
    content: '';
    width: 16px;
    height: 16px;
    background-color: var(----purple, #5C4EFA);
    border-radius: 50%;
    position: absolute;
    top: 2px;
    left: 2px;
    transition: transform 0.2s ease;
}

/* Active state */
.switch-input:checked+.switch-slider {
    background: var(----purple, #5C4EFA);
}

.switch-input:checked+.switch-slider::before {
    transform: translateX(20px);
    background-color: #ffffff;


}

.add-link-button {
    display: flex;
    padding: 3px 2px;
    justify-content: center;
    align-items: center;
    gap: 7px;
    align-self: stretch;

    border-radius: 3px;
    background: var(----dark-gray, #57575B);
    color: var(----editor-text, #D9D9D9);
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    border: none;
    width: 100%;
}

.dropdown-links-section {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.dropdown-links-section hr{
    width: 100%;
}

/* Link Item Container */
.link-item {
    position: relative;
    padding: 8px;
    border-radius: 4px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  /* Remove Link Icon */
  .remove-link-icon {
    position: absolute;
    top: 15px;
    right: -10px;
    font-size: 16px;
    color: #d9534f;
    cursor: pointer;
    transition: color 0.2s ease;
  }
  
  .remove-link-icon:hover {
    color: #c9302c;
  }
  