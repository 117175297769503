/* src/components/css/MediaPanel.css */

/* Existing styles... */

.media-panel {
  padding: 16px;
}

/* Upload Buttons */
.upload-buttons {
  display: flex;
  gap: 16px;
  margin-bottom: 16px;
  align-items: center
}

.upload-button {
  background-color: #28a745;
  color: #fff;
  padding: 10px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  position: relative;
}

.upload-button:hover {
  background-color: #218838;
}

.dropzone {
  flex: 1;
  border: 2px dashed #6c757d;
  border-radius: 4px;
  padding: 10px;
  text-align: center;
  color: #6c757d;
  cursor: pointer;
}

.dropzone:hover {
  background-color: #f8f9fa;
}

/* Filter Buttons */
.filter-buttons {
  display: flex;
  gap: 8px;
  margin-bottom: 16px;
  justify-content: space-between;
}

.filter-buttons button {
  padding: 8px 16px;
  border: none;
  background-color: #e0e0e0;
  cursor: pointer;
  border-radius: 4px;
}

.filter-buttons button.active {
  background-color: #007bff;
  color: #fff;
}

.filter-buttons button:hover {
  background-color: #d0d0d0;
}

/* Media Grid for Square Items */
.media-grid {
  display: grid;
  grid-template-columns: repeat(2, 100px);
  gap: 16px;
  justify-content: space-evenly;
  justify-items: center;
}

.media-item {
  position: relative;
  overflow: hidden;
  width: 100px;
  /* Fixed width */
  height: 100px;
  /* Fixed height to make it square */
  border-radius: 8px;
}

.media-preview {
  width: 100%;
  height: 100%;
  background-color: #f9f9f9;
  display: flex;
  justify-content: center;
  align-items: center;
}

.media-preview img,
.media-preview video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* Ensures the content fits within the square */
}

.media-preview .file-icon {
  font-size: 24px;
  text-align: center;
}



/* Overlay styles */
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0);
  color: #fff;
  opacity: 0;
  transition: background-color 0.3s, opacity 0.3s;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.media-item:hover .overlay {
  background-color: rgba(0, 0, 0, 0.6);
  opacity: 1;
}

.media-name {
  margin: 0;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  padding: 8px;
}

.overlay-buttons {
  display: flex;
  gap: 8px;
  margin-top: 8px;
}

.overlay-button {
  background-color: #007bff;
  border: none;
  padding: 6px 12px;
  border-radius: 4px;
  cursor: pointer;
  color: #fff;
  font-size: 14px;
}

.overlay-button:hover {
  background-color: #0056b3;
}

.remove-button {
  background-color: #dc3545;
}

.remove-button:hover {
  background-color: #c82333;
}

/* Preview Modal */
.preview-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}

.preview-content {
  position: relative;
  background-color: #fff;
  padding: 16px;
  border-radius: 8px;
}

.preview-content img,
.preview-content video {
  max-width: 100%;
  max-height: 80vh;
}

.close-button {
  position: absolute;
  top: 8px;
  right: 8px;
  background-color: #dc3545;
  color: #fff;
  border: none;
  padding: 6px 12px;
  border-radius: 4px;
  cursor: pointer;
}

.close-button:hover {
  background-color: #c82333;
}

.media-name-input {
  width: 80%;
  padding: 4px;
  font-size: 16px;
  text-align: center;
  border: none;
  border-radius: 4px;
}

.media-name-input:focus {
  outline: none;
}

.media-item.editing .overlay {
  background-color: rgba(0, 0, 0, 0.6);
  opacity: 1;
}

.media-item.editing:hover .overlay {
  background-color: rgba(0, 0, 0, 0.6);
  opacity: 1;
}

.search-bar {
  margin: 16px 0;
}

.search-bar input {
  padding: 8px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  
}

.search-bar input:focus {
  outline: none;
  border-color: #007bff;
}