.topbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #313031;
  border-bottom: 1px solid var(----dark-gray, #57575B);
}

.project-info {
  display: flex;
  align-items: left ;
  gap: 10px;
}

.return-button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.5rem;
  color: rgba(131, 131, 137, 1);
}

.project-details {
  display: flex;
  flex-direction: column;
  line-height: 1.2;
}

.project-name {
  color: var(----editor-text, #D9D9D9);
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.project-description {
  font-size: 0.85rem;
}

.project-url {
  color: var(----not-selected, #838389);
  text-align: center;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-decoration-skip-ink: auto;
  text-decoration-thickness: auto;
  text-underline-offset: auto;
  text-underline-position: from-font;
  display: flex;
  align-items:baseline;
}

.project-details-url{
  display: flex;
  align-items: flex-end;
  gap: 5px;
  color: #666;
  font-size: 0.875rem;

}

.actions {
  display: flex;
}

.undo-button,
.redo-button,
.preview-button {
  background: none;
  border: none;
  cursor: pointer;
  align-items: center;
  font-size: 1.5rem;
  display: flex;
  color: rgba(131, 131, 137, 1);

}

.resize-controls {
  display: flex;
  align-items: center;
  gap: 10px;

}

.resize-button {
  padding: 3px;
  color: rgba(131, 131, 137, 1);
  background-color: transparent;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.resize-button.selected {
  border-radius: 4.8px;
  background: rgba(255, 255, 255, 0.50);
  color: var(----white, #FFF);
}

.resize-buttons {
  display: flex;
  align-items: center;
}

.scale-percentage {
  color: #D9D9D9;
  text-align: center;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.input {
  padding: 8px;
  border-radius: 4px;
  width: 80px;
  height: 10px;
  color: rgba(255, 255, 255, 0.72);
  font-family: Montserrat;
  background: rgba(0, 0, 0, 0.45);
  border: none;
}

.export-section .button {
  display: flex;
  width: 106px;
  height: 30px;
  padding: 4px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  border: none;

  border-radius: 5px;
  background: var(----purple, #5C4EFA);

  color: var(----white, #FFF);
  text-align: center;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.export-section .export-cloud {
  font-size: 20px;
}


.export-section {
  display: flex;
  align-items: center;
  gap: 10px;
}

.autosave-status {
  color: var(----editor-text, #D9D9D9);
  text-align: center;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}