body {
  cursor: default;
}


p {
  word-wrap: break-word;
  /* Forces long words to wrap to the next line */
  white-space: normal;
  /* Ensures text wraps within the container */
  overflow-wrap: break-word;
  /* Alternative for word-wrap; supported in most browsers */
}




.container {
  width: 100%;
  /* or use a specific width like 300px */
  max-width: 500px;
  /* Sets a maximum width to control wrapping */
  overflow: hidden;
  /* Ensures any overflowed content remains contained */
  padding: 10px;
  /* Optional padding for better readability */
  box-sizing: border-box;
  /* Ensures padding is included in total width */
}

.editor {
  display: flex;
  flex-direction: column;
}

.controls {
  display: flex;
  flex-direction: column;
}

/* src/App.css */
.app {
  display: flex;
  height: 100vh;
  overflow: hidden;
}



/* src/App.css */
.layout {
  display: flex;
  height: 100vh;
  /* Full viewport height */
}


.app {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}

.content-container {
  display: flex;
  flex-grow: 1;
  height: calc(100vh - 60px);
  /* Adjust based on Topbar height */
}

/* Sidebar styling */
.sidebar {
  max-width: 300px;
  min-width: 300px;
  background-color: #313031;
  box-sizing: border-box;
  overflow-y: auto;
}

/* Ensure content list scales responsively */
.main-content {
  flex-grow: 1;
  box-sizing: border-box;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: darkgrey;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  /* Ensures alignment to the top */
}

.content-list {
  background-color: #F5F5F5;
  min-height: 100vh;
  height: auto;
  max-width: 100%;
  overflow: hidden;

}


/* General container styling */
.container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  /* Space between each section */
  padding: 16px;
  background-color: #e0e0e0;
  /* Example background color */
}

/* Section styling */
.section {
  background-color: #f5f5f5;
  padding: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

/* Row styling */
.row {
  display: flex;
  justify-content: space-between;
  gap: 16px;
  /* Space between columns */
}

/* Item styling */
.item {
  background-color: #c0c0c0;
  /* Example item background */
  padding: 16px;
  border-radius: 4px;
  flex: 1;
  /* Grow and shrink as needed */
}


.structure-panel {
  width: 300px;
  max-width: 350px;
  min-width: 300px;
  height: 100%;
  /* Make the panel fill the available height */
  max-height: 100vh;
  /* Restrict the height to the viewport */
  padding: 16px;
  background-color: #313031;
  overflow-y: auto;
  /* Enable vertical scrolling */
  box-sizing: border-box;
  /* Ensure padding and border are included in height */

  color: var(----editor-text, #D9D9D9);
  text-align: left;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.structure-panel h3 {
  margin-top: 0;
  margin-bottom: 16px;
}

.structure-panel div {
  font-size: 14px;
}

/* Shared scrolling styles */
.scrollable-panel {
  overflow-y: auto;
  /* Enable vertical scrolling */
  height: 100%;
  /* Take full available height */
  max-height: 100vh;
  /* Constrain to viewport height */
  box-sizing: border-box;
  /* Include padding and border in height calculation */
  padding: 16px;
}

/* Media Panel */
.media-panel {

  width: 300px;
  max-width: 350px;
  min-width: 300px;
  background-color: #313031;

  color: var(----editor-text, #D9D9D9);
  text-align: left;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

/* Specific styles for media items */
.media-list {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.media-item {
  border: 1px solid #ccc;
  padding: 8px;
  background-color: #313031;
}

.media-item img,
.media-item video {
  max-width: 100%;
  height: auto;
}